<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="ths_data"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="grey" class="mb-2" v-on="on" v-bind="attrs"
        >mdi-calendar</v-icon
      >
    </template>
    <v-date-picker
      ref="picker"
      v-model="ths_data"
      no-title
      scrollable
      locale="th"
      @change="onChange"
      :max="maxDate"
      :min="minDate"
    />
  </v-menu>
</template>

<script>
import * as moment from 'moment';

export default {
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    maxDate: {
      type: [String, Number],
      default: null
    },
    minDate: {
      type: [String, Number],
      default: null
    },
    label: {
      type: [String],
      default: null
    },
    key_name: {
      type: [String],
      default: null
    },
    error_messages: {
      type: [String],
      default: null
    },
    datepicker_type: {
      type: String,
      default: null
    },
    hideDetails: {
      type: Boolean
    },
    outlined: {
      type: Boolean
    },
    rounded: {
      type: Boolean
    },
    dense: {
      type: Boolean
    },
    customAllowedDates: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean
    },
    hour: {
      type: [String, Number],
      default: 0
    },
    minute: {
      type: [String, Number],
      default: 0
    },
    second: {
      type: [String, Number],
      default: 0
    },
    icon: {
      type: String,
      default: ''
    }
  },
  watch: {
    value: function(newVal, oldVal) {
      this.ths_data = newVal;
    },
    menu(val) {
      if (this.datepicker_type)
        val &&
          setTimeout(
            () => (this.$refs.picker.activePicker = this.datepicker_type)
          );
    }
  },
  mounted() {
    this.ths_data = this.value;
  },
  data() {
    return {
      menu: false,
      ths_data: ''
    };
  },
  methods: {
    onChange() {
      this.$refs.menu.save(this.formatDate(this.ths_data));
      this.$emit(
        'onChange',
        moment(this.ths_data)
          .set({
            hour: this.hour,
            minute: this.minute,
            second: this.second
          })
          .format('YYYY-MM-DDTHH:mm:ss')
      );
    },
    formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(this.ths_data).format('YYYY'));
      let m_d = moment(this.ths_data).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
    allowedDates(val) {
      let allowedDates = this.customAllowedDates;
      if (!allowedDates) allowedDates = [];

      return !allowedDates.includes(new Date(val).getDay());
    }
  }
};
</script>

<style lang="scss" scope>
.panel {
  position: relative;
}
.wrap-auto-complete {
  position: absolute;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.42);
  top: 40px;
  z-index: 20;
}
.box-suggestion {
  width: 100%;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  padding: 10px 10px;
  cursor: pointer;
}
.box-suggestion:hover {
  background-color: #f5f5f5;
}
</style>
