<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text"
      >เลือกการชำระเงิน</v-card-title
    >
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="4" class="text-center">
          <v-btn class="py-10 px-10" large @click="overdue()">ค้างชำระ</v-btn>
        </v-col>
        <v-col cols="4" class="text-center">
          <v-btn class="py-10 px-10" large @click="pay()">ชำระเงิน</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-btn outlined class="px-5 ml-10" color="grey" @click="$emit('onClose')"
        >กลับ</v-btn
      >
      <v-spacer />
    </v-card-actions>

    <div v-if="dialogSuccess">
      <v-dialog persistent scrollable max-width="600" v-model="dialogSuccess">
        <PaySuccess
          :item_id="document_id"
          @onClose="(dialogSuccess = false), $emit('onClose')"
        ></PaySuccess>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import PaySuccess from './PaySuccess';
import PrintDocument from '@/components/PrintDocument/printDocument.vue';

export default {
  components: {
    PaySuccess,
    PrintDocument
  },
  props: {
    id: {
      required: false,
      type: String,
      default: ''
    }
  },
  data: () => ({
    dialogSuccess: false,
    dataPrint: null,
    document_id: ''
  }),
  methods: {
    async overdue() {
      this.dialogSuccess = true;
      let body = {
        token: this.$jwt.sign({ document_id: this.id }, this.$privateKey, {
          noTimestamp: true
        })
      };
      await this.$axios
        .post(`${this.$baseUrl}/document/open_quotation_bill`, body)
        .then(async res => {
          console.log(res.result);
          this.$router.push({
            name: `document-invoice-update`,
            params: { id: res.result }
          });
          this.$emit('success');
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      // await this.$axios
      //   .post(`${this.$baseUrl}/document/open_quotation_bill`, body)
      //   .then(async res => {
      //     console.log(res);
      //     this.document_id = res.result;
      //     this.dialogSuccess = true;
      //   })
      //   .catch(err => {
      //     console.log('err', err);
      //   });
    },
    pay() {
      this.$router.push({
        name: 'document-quotation-pay',
        params: { id: this.id }
      });
    }
  }
};
</script>
