<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-5 primary--text"
      >เลือกปีที่ภาษีเก่าขาด</v-card-title
    >
    <p class="text-center">ปีป้ายภาษีล่าสุด ที่ติดอยู่หน้ารถลูกค้า</p>
    <v-card-text class="py-5 my-5">
      <v-row class="justify-center">
        <v-btn
          :color="form.taxout_date == 0 ? '#E0E0E0' : 'white'"
          class="mx-5 mb-5"
          item-value="value"
          @click="
            setYear((form.taxout_date = 0)),
              () => {
                form.taxout_date == 0 && !isUpdate
                  ? (form.taxout_date = 3)
                  : (form.taxout_date = 0);
              }
          "
        >
          <span class="text-h6 grey--text">{{
            formatDate(list.text_taxout_date[0].name)
          }}</span>
        </v-btn>
        <v-btn
          :color="form.taxout_date == 1 ? '#E0E0E0' : 'white'"
          class="mx-5 mb-5"
          item-value="value"
          @click="
            setYear((form.taxout_date = 1)),
              () => {
                form.taxout_date == 1 && !isUpdate
                  ? (form.taxout_date = 3)
                  : (form.taxout_date = 1);
              }
          "
        >
          <span class="text-h6 grey--text">{{
            formatDate(list.text_taxout_date[1].name)
          }}</span>
        </v-btn>
        <v-btn
          :color="form.taxout_date == 2 ? '#E0E0E0' : 'white'"
          class="mx-5 mb-5"
          item-value="value"
          @click="
            setYear((form.taxout_date = 2)),
              () => {
                form.taxout_date == 2 && !isUpdate
                  ? (form.taxout_date = 3)
                  : (form.taxout_date = 2);
              }
          "
        >
          <span class="text-h6 grey--text">{{
            formatDate(list.text_taxout_date[2].name)
          }}</span>
        </v-btn>
        <v-btn
          :color="form.taxout_date == 3 ? '#E0E0E0' : 'white'"
          class="mx-5 mb-5"
          item-value="value"
          @click="setYear((form.taxout_date = 3))"
        >
          <span class="text-h6 grey--text">{{
            formatDate(list.text_taxout_date[3].name)
          }}</span>
        </v-btn>
        <v-btn
          :color="form.taxout_date == 4 ? '#E0E0E0' : 'white'"
          class="mx-5 mb-5"
          item-value="value"
          @click="setYear((form.taxout_date = 4))"
        >
          <span class="text-h6 grey--text">{{
            formatDate(list.text_taxout_date[4].name)
          }}</span>
        </v-btn>
      </v-row>
    </v-card-text>
    <!-- <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()"
        >ยกเลิก</v-btn
      >
      <v-btn class="px-10 mr-15" color="primary" @click="save()"
        >บันทึกข้อมูล</v-btn
      >
    </v-card-actions> -->
  </v-card>
</template>

<script>
import * as moment from 'moment';

export default {
  props: {
    item: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    // taxout_date: null,
    form: { taxout_date: null },
    total_tax_price: [null, null, null, null],
    list: {
      text_taxout_date: [
        {
          value: '0',
          name: moment().add(-3, 'years').format('YYYY'),
        },
        {
          value: '1',
          name: moment().add(-2, 'years').format('YYYY'),
        },
        {
          value: '2',
          name: moment().add(-1, 'years').format('YYYY'),
        },
        {
          value: '3',
          name: moment().add('years').format('YYYY'),
        },
        {
          value: '4',
          name: moment().add(1, 'years').format('YYYY'),
        },
      ],
    },
  }),
  watch: {
    item: function (newVal, oldVal) {
      this.form.taxout_date = newVal;

      // this.$v.form.taxout_date.$reset();
    },
    item: function (newVal, oldVal) {
      this.total_tax_price[3] = newVal;
      // this.$v.form.taxout_date.$reset();
    },
  },
  methods: {
    setYear() {
      this.totalYear = this.formatDate(
        this.list.text_taxout_date[this.form.taxout_date].name
      );
      this.form.taxout_date = this.totalYear;
      this.item = moment(this.item)
        .set({ year: this.form.taxout_date - 543 })
        .format('YYYY-MM-DDTHH:mm:ss');
      console.log(this.item);
      console.log(this.total_tax_price[3]);
      this.save();
      this.close();
    },
    formatDate(date) {
      if (!date) return '';
      let m_d = moment().format('DD/MM');
      let year = parseInt(moment(date).format('YYYY'));
      return `${year + 543}`;
    },
    close() {
      this.$emit('onClose');
    },
    save() {
      // this.$v.item.$touch();
      this.$emit('onSubmit', this.item);
    },
  },
  computed: {
    isUpdate() {
      return this.item;
    },
  },
};
</script>
